const throwIfNoSessionStorage = () => {
  if (typeof window === undefined || !window.sessionStorage) {
    throw Error("Session storage unavailable")
  }
}

export const setItem = (key: string, data: unknown) => {
  throwIfNoSessionStorage()
  window.sessionStorage.setItem(key, JSON.stringify(data))
}

export const getItem = (key: string, options?: { parse: boolean }): unknown => {
  throwIfNoSessionStorage()
  const item = window.sessionStorage.getItem(key)
  return !item ? null : options?.parse ? JSON.parse(item) : item
}
