/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react"
import { navigate } from "gatsby"
import scrollIntoView from "scroll-into-view"
import { helpers, sendEvent } from "@life-without-barriers/utilities"
import {
  Elm,
  AnsweredQuestion,
  AnsweredQuestionData
} from "../../../../components/foster-care/SelfAssessment/Main"
import ElmComponent from "../../../../components/foster-care/ElmComponent/ElmComponent"
import SelfAssessmentContainer from "../../../../components/foster-care/SelfAssessment/Container"
import { setItem } from "../../../../lib/foster-care/sessionStorage"

const eventNameSpace = "Self assessment"
const saEventName = "self.assessment.funnel"

interface Props {
  location: { pathname: string }
}

/**
 * formatStringForGA
 * Strip everything from a string except for alphanumeric chars and spaces
 */
const formatStringForGA = (lbl: string) => {
  const stripped = lbl.replace(/[^a-zA-Z0-9\s]/g, "")
  return helpers.dasherize(stripped.toLowerCase())
}

const handleScrollUpEvent = () => {
  const elmAppBody = document.querySelector(".elm-app-body") as HTMLElement

  if (!elmAppBody) {
    return
  }

  scrollIntoView(elmAppBody, {
    time: 500,
    align: {
      top: elmAppBody.scrollHeight
    }
  })
}

const handleAssessmentStarted = () => {
  sendEvent(saEventName, `${eventNameSpace} started`, "Click", "Started")
}

const handleAssessmentComplete = () => {
  sendEvent(saEventName, "Self assessment navigation", "Click", "Completed")
  void navigate("/foster-care/can-i-be-a-carer/self-assessment/lead/")
}

const handleAnsweredQuestion = ({
  label,
  selectedAnswer
}: AnsweredQuestion) => {
  if (selectedAnswer) {
    sendEvent(
      saEventName,
      `${eventNameSpace} answer`,
      "Click",
      formatStringForGA(`${label} ${selectedAnswer.value}`)
    )
    sendEvent(
      "lwb.ga",
      `${eventNameSpace} answer`,
      "Click",
      formatStringForGA(`${label} ${selectedAnswer.value}`)
    )
  }
}

const handleCurrentQuestion = ({ label }: AnsweredQuestion) => {
  sendEvent(
    saEventName,
    `${eventNameSpace} question`,
    "Click",
    formatStringForGA(label)
  )
  sendEvent(
    "lwb.ga",
    `${eventNameSpace} question`,
    "Click",
    formatStringForGA(label)
  )
}

const handleAnsweredQuestions = ([answers, score]: AnsweredQuestionData) => {
  saveAnswers(answers, score)
}

const handleAttemptToEditAnswer = ({ label }: AnsweredQuestion) => {
  sendEvent(
    saEventName,
    `${eventNameSpace} attempt edit answer`,
    "Click",
    formatStringForGA(label)
  )
}

const saveAnswers = (data: AnsweredQuestion[], score: number) => {
  const selectedAnswers = data.map((q) => ({
    question: q.text,
    answer: q.selectedAnswer ? q.selectedAnswer.value : ""
  }))

  setItem("self-assessment-answers", { answers: selectedAnswers, score })
}

/**
 * handleSetFocusToAnswerButton
 * Find the button with the data attribute data-question="${attr}"
 * inside of .current-answered-question
 * Use the DOM to set it's focus
 */
const handleSetFocusToAnswerButton = (attr: string) => {
  window.requestAnimationFrame(() => {
    const answeredQuestion = document.querySelector(
      ".current-answered-question"
    ) as HTMLElement

    if (!answeredQuestion) {
      return
    }

    const target = answeredQuestion.querySelector(
      `[data-question="${attr}"]`
    ) as HTMLElement

    if (!target) {
      return
    }

    target.focus()
  })
}

/**
 * handleSetFocusToNextQuestionButton
 * Find the last 'Next Question' button inside of .answered-questions
 * Use the DOM to set it's focus
 */
const handleSetFocusToNextQuestionButton = () => {
  window.requestAnimationFrame(() => {
    const answeredQuestions = document.querySelector(".answered-questions")

    if (!answeredQuestions) {
      return
    }

    const btns =
      answeredQuestions.querySelectorAll<HTMLElement>(".next-question")

    if (!btns || !btns.length) {
      return
    }

    // select the last btn in a nodelist
    btns.item(btns.length - 1).focus()
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setupPorts = ({ ports }: any) => {
  ports.close.subscribe(() => navigate("/foster-care/can-i-be-a-carer/"))
  ports.scrollUp.subscribe(handleScrollUpEvent)
  ports.emitAssessmentStarted.subscribe(handleAssessmentStarted)
  ports.emitAssessmentComplete.subscribe(handleAssessmentComplete)
  ports.emitAnsweredQuestion.subscribe(handleAnsweredQuestion)
  ports.emitNewestQuestion.subscribe(handleCurrentQuestion)
  ports.emitAttemptToEditAnswer.subscribe(handleAttemptToEditAnswer)
  ports.emitAnsweredQuestions.subscribe(handleAnsweredQuestions)
  ports.emitSetFocusToAnswerButton.subscribe(handleSetFocusToAnswerButton)
  ports.emitSetFocusToNextQuestionButton.subscribe(
    handleSetFocusToNextQuestionButton
  )
}

/**
 * I'm not proud of this.
 * The browser chrome in Safari on iOS is annoying
 * The hack of tricking the window to scroll to hide it is
 * inconsistant: window.scrollTo(0, 1)
 * So instead, try and detect ios and safari - if so, apply padding
 * at the bottom to push it above the chrome.
 */
const iOSSafari = () => {
  if (typeof window === "undefined") {
    return
  }

  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit && !ua.match(/CriOS/i)
}

const SelfAssessmentIndexPage = ({ location }: Props) => (
  <SelfAssessmentContainer location={location}>
    <ElmComponent
      className="h-100 foo"
      src={Elm.Main}
      ports={setupPorts}
      flags={iOSSafari()}
    />
  </SelfAssessmentContainer>
)

export default SelfAssessmentIndexPage
