/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react"

interface Props {
  src: {
    init: any
  }
  flags?: any
  ports?: any
  className?: string
}

export default class ElmComponent extends React.Component<Props> {
  private nodeRef = React.createRef<HTMLDivElement>()

  public componentDidMount() {
    const app = this.props.src.init({
      node: this.nodeRef.current,
      flags: this.props.flags
    })
    if (typeof this.props.ports !== "undefined") {
      this.props.ports(app)
    }
  }

  public shouldComponentUpdate() {
    return false
  }

  public render() {
    return <div className={this.props.className} ref={this.nodeRef} />
  }
}
